import React, { useState, useEffect } from 'react';

// Missing champions
export default function Missing(props) {

  const { champions, userInput, colordata } = props


  if (userInput) return (<></>)

  const missingChampions = () => {
    const keys = Object.keys(champions)
    return keys.filter((key) => {
      const _color = colordata.find((cdata) => cdata[key])
      if (!_color) return true 
      return false
    }).map((missing) => {
      return (<div>
              {missing}
              </div>)
    })
  }


  return (<div>
          <h3>Missing Color Data for Champions:</h3>
          {missingChampions()}
          </div>)
}
