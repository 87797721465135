const colordata = [
    {
        "Aatrox": ["red"]
    },
    {
        "Ahri": ["red", "blue"]
    },
    {
        "Akali": ["red"]
    },
    {
        "Alistar": ["red"]
    },
    {
        "Amumu": ["blue"]
    },
    {
        "Anivia": ["blue"]
    },
    {
        "Annie": ["blue", "red"]
    },
    {
        "Aphelios": ["green"]
    },
    {
        "Ashe": ["blue"]
    },
    {
        "AurelionSol": ["red", "blue"]
    },
    {
        "Azir": ["blue", "green"]
    },
    {
        "Bard": ["red"]
    },
    {
        "Blitzcrank": ["red"]
    },
    {
        "Brand": ["blue"]
    },
    {
        "Braum": ["red", "blue"]
    },
    {
        "Caitlyn": ["blue"]
    },
    {
        "Camille": ["red"]
    },
    {
        "Cassiopeia": ["blue"]
    },
    {
        "Chogath": ["blue"]
    },
    {
        "Corki": ["green"]
    },
    {
        "Darius": ["red"]
    },
    {
        "Diana": ["blue"]
    },
    {
        "Draven": ["red"]
    },
    {
        "DrMundo": ["green"]
    },
    {
        "Ekko": ["red"]
    },
    {
        "Elise": ["red"]
    },
    {
        "Evelynn": ["green"]
    },
    {
        "Ezreal": ["blue"]
    },
    {
        "Fiddlesticks": ["blue"]
    },
    {
        "Fiora": ["green"]
    },
    {
        "Fizz": ["red"]
    },
    {
        "Galio": ["blue"]
    },
    {
        "Gangplank": ["green", "blue"]
    },
    {
        "Garen": ["red"]
    },
    {
        "Gnar": ["red", "blue"]
    },
    {
        "Gragas": ["red", "blue"]
    },
    {
        "Graves": ["red", "green"]
    },
    {
        "Hecarim": ["red"]
    },
    {
        "Heimerdinger": ["blue"]
    },
    {
        "Illaoi": ["blue", "green"]
    },
    {
        "Irelia": ["red", "green"]
    },
    {
        "Ivern": ["blue"]
    },
    {
        "Janna": ["blue"]
    },
    {
        "JarvanIV": ["red"]
    },
    {
        "Jax": ["green"]
    },
    {
        "Jayce": ["red"]
    },
    {
        "Jhin": ["blue"]
    },
    {
        "Jinx": ["green"]
    },
    {
        "Kaisa": ["green"]
    },
    {
        "Kalista": ["red"]
    },
    {
        "Karma": ["blue"]
    },
    {
        "Karthus": ["green"]
    },
    {
        "Kassadin": ["green"]
    },
    {
        "Katarina": ["red"]
    },
    {
        "Kayle": ["green"]
    },
    {
        "Kayn": ["red", "green"]
    },
    {
        "Kennen": ["red"]
    },
    {
        "Khazix": ["red"]
    },
    {
        "Kindred": ["green"]
    },
    {
        "Kled": ["red"]
    },
    {
        "KogMaw": ["green"]
    },
    {
        "Leblanc": ["red"]
    },
    {
        "LeeSin": ["red"]
    },
    {
        "Leona": ["red"]
    },
    {
        "Lillia": ["blue"]
    },
    {
        "Lissandra": ["blue"]
    },
    {
        "Lucian": ["red"]
    },
    {
        "Lulu": ["blue"]
    },
    {
        "Lux": ["blue"]
    },
    {
        "Malphite": ["red", "blue"]
    },
    {
        "Malzahar": ["blue"]
    },
    {
        "Maokai": ["blue"]
    },
    {
        "MasterYi": ["green"]
    },
    {
        "MissFortune": ["red"]
    },
    {
        "MonkeyKing": ["red"]
    },
    {
        "Mordekaiser": ["green"]
    },
    {
        "Morgana": ["blue"]
    },
    {
        "Nami": ["blue"]
    },
    {
        "Nasus": ["green"]
    },
    {
        "Nautilus": ["red"]
    },
    {
        "Neeko": ["blue"]
    },
    {
        "Nidalee": ["red"]
    },
    {
        "Nocturne": ["red"]
    },
    {
        "Nunu": ["red", "blue"]
    },
    {
        "Olaf": ["red"]
    },
    {
        "Orianna": ["blue"]
    },
    {
        "Ornn": ["blue", "green"]
    },
    {
        "Pantheon": ["red"]
    },
    {
        "Poppy": ["blue"]
    },
    {
        "Pyke": ["red"]
    },
    {
        "Qiyana": ["red"]
    },
    {
        "Quinn": ["red"]
    },
    {
        "Rakan": ["red"]
    },
    {
        "Rammus": ["blue"]
    },
    {
        "RekSai": ["red"]
    },
    {
        "Renekton": ["red"]
    },
    {
        "Rengar": ["red"]
    },
    {
        "Riven": ["red", "blue"]
    },
    {
        "Rumble": ["blue", "red"]
    },
    {
        "Ryze": ["blue", "green"]
    },
    {
        "Sejuani": ["blue"]
    },
    {
        "Senna": ["blue"]
    },
    {
        "Sett": ["red"]
    },
    {
        "Shaco": ["red"]
    },
    {
        "Shen": ["blue"]
    },
    {
        "Shyvana": ["green"]
    },
    {
        "Singed": ["blue"]
    },
    {
        "Sion": ["blue", "green"]
    },
    {
        "Sivir": ["green", "blue"]
    },
    {
        "Skarner": ["blue"]
    },
    {
        "Sona": ["blue"]
    },
    {
        "Soraka": ["blue"]
    },
    {
        "Swain": ["blue"]
    },
    {
        "Sylas": ["red"]
    },
    {
        "Syndra": ["blue"]
    },
    {
        "TahmKench": ["red"]
    },
    {
        "Taliyah": ["red", "blue"]
    },
    {
        "Talon": ["red"]
    },
    {
        "Taric": ["blue"]
    },
    {
        "Teemo": ["blue"]
    },
    {
        "Thresh": ["blue"]
    },
    {
        "Tristana": ["red"]
    },
    {
        "Trundle": ["red", "blue"]
    },
    {
        "Tryndamere": ["green"]
    },
    {
        "TwistedFate": ["red", "blue"]
    },
    {
        "Twitch": ["green"]
    },
    {
        "Udyr": ["red"]
    },
    {
        "Urgot": ["green"]
    },
    {
        "Varus": ["blue"]
    },
    {
        "Vayne": ["green"]
    },
    {
        "Veigar": ["blue", "green"]
    },
    {
        "Velkoz": ["blue"]
    },
    {
        "Vi": ["red"]
    },
    {
        "Viktor": ["blue", "green"]
    },
    {
        "Vladimir": ["green"]
    },
    {
        "Volibear": ["red"]
    },
    {
        "Warwick": ["red"]
    },
    {
        "Xayah": ["green"]
    },
    {
        "Xerath": ["blue"]
    },
    {
        "XinZhao": ["red"]
    },
    {
        "Yasuo": ["red", "green"]
    },
    {
        "Yone": ["red", "green"]
    },
    {
        "Yorick": ["blue", "green"]
    },
    {
        "Yuumi": ["blue", "green"]
    },
    {
        "Zac": ["red", "blue"]
    },
    {
        "Zed": ["red"]
    },
    {
        "Ziggs": ["blue"]
    },
    {
        "Zilean": ["blue"]
    },
    {
        "Zoe": ["blue"]
    },
    {
        "Zyra": ["blue"]
    }
]

export default colordata