import React, { useState, useEffect } from 'react';
import './App.css';
import Filter from "./Filter.js";
import Missing from "./Missing.js";
import {leaguedata} from "./util.js";
import colordata from "./colordata.js";

function App() {
  const [getInput, setInput] = useState("")
  const [getChampions, setChampions] = useState({})
  const [getVer, setVer] = useState("10.16.1")

  const textinput = (event) => {
      const userinput = event.target.value
      setInput(userinput)
  }

  useEffect(() => { //downloads data at startup into app state
    leaguedata().then((championdata) =>  {
      setVer(championdata.version)
      setChampions(championdata.data)
    })
  }, [getVer])
  
  function makeColors() {
    const printColors = () => {
      const keys = Object.keys(getChampions)
      const result = keys.map((key) => {
        const o = {}
        o[key] = ""
        return o
      })
      console.log(JSON.stringify(result))
    }
    return (<button onClick={printColors}>colordata</button>)
  }


  return (
    <div className="App">

      <input className="typein" type="text" placeholder="Champion here" value={getInput} onChange={textinput} />

      {makeColors()}

      <div className="container">
        <div className="column">
          <Filter champions={getChampions} userInput={getInput} version={getVer} colordata={colordata} color="red" />
        </div>
        <div className="column">
          <Filter champions={getChampions} userInput={getInput} version={getVer} colordata={colordata} color="blue" />
        </div>
        <div className="column">
          <Filter champions={getChampions} userInput={getInput} version={getVer} colordata={colordata} color="green" />
        </div>
      </div>

      <Missing champions={getChampions} userInput={getInput} colordata={colordata} />
    </div>

  );
}

export default App;
