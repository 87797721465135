import React, { useState, useEffect } from 'react';

function icon(version, championData) { //name, not id
  return `http://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${championData.image.full}`
}

function Filter(props) {
  // const champions = props.champions
  // const userInput = props.userInput
  // const version = props.version
  const { champions, userInput, version, colordata, color } = props
  
  const [ getColoredChampions, setColoredChampions ] = useState([])

  useEffect(() => {
    const keys = Object.keys(champions)
    const champs = keys.filter((key) => {
      const _color = colordata.find((cdata) => cdata[key])
      if (!_color) return false // champion color data not found
      return _color[key].some((c) => c === color)
    }).map((key) => champions[key]) 
    setColoredChampions(champs) 
  }, [champions, color, colordata])


  const showChampions = () => {
    return getColoredChampions 
      .filter((champ) => {
        const enter = userInput.toLowerCase()
        const name = champ.name.toLowerCase()
        const id = champ.id.toLowerCase()
        // return name.indexOf(enter) === 0 || id.indexOf(enter) === 0
        return name.indexOf(enter) !== -1 || id.indexOf(enter) !== -1
        
      })
      .map((champ) => {
        return (<li key={champ.id}>
                <div className="champion-container">
                <div className="champion-name">{champ.name}</div>
                <img src={icon(version, champ)} alt={champ.name} />
                </div>
                </li>)
      })
  }

  return (
      <ul className={`filterlist color-${color}`}>
      {showChampions()}
    </ul>
  )
}

export default Filter;
