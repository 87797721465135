function leaguedata() {
    return fetch("https://ddragon.leagueoflegends.com/api/versions.json")
        .then((data) => data.json())
        .then((versions) => {
            const version = versions[0]
            return fetch(`https://ddragon.leagueoflegends.com/cdn/${version}/data/en_US/champion.json`)
        })
        .then((championdata) => championdata.json())
}

export {
    leaguedata
}
